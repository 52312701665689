import React, { useEffect, useState } from "react"
import { Alert, Label, Input } from "reactstrap"
import { useHistory, useLocation } from "react-router-dom"
import { RedeemCodes } from "../../../../Code/Data"
import { parseQuerystring, scrollToElement } from "../../../../Code/Utilities"
import { useRedemptionData } from "../../../../Context/redemption"
import { useAppData } from "../../../../Context/appData"
import CharitySelector from "../../../Common/CharitySelector"
import { Button } from "../../../index"
import styles from "./styles.module.scss"
import { useUserData } from "../../../../Context/user"
import { usePurchaseData } from "../../../../Context/purchase"
function Step2({ onBack, onContinue, setSuccess, codeAsEntered, charityId, multi, codes, rc, loggedInUser }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [shareInfo, setShareInfo] = useState(false)
  const {    
    redemptionData,
    setRedemptionData,
    getRedeemCodeInfo,
    redemptionPageData,
    redeemCodeInfo,
  } = useRedemptionData()
  const { fullCharityList, loading: loadingAppData } = useAppData()
  const { Charities } = redemptionData
  const { charityList, setCharityList } = useState()
  const { addToDonationData } = usePurchaseData()
  const addToDonationAmount = addToDonationData.amount || 0
  const history = useHistory()
  const { search } = useLocation()
  const { HideAmountAllOrders } = loggedInUser || {}
  const [total, setTotal] = useState()
  const [isEditMode, setEditMode] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      if (multi && codes.length > 1) {
        let res = await getCodesSum()
        setTotal(res)
      }
      else {
        setTotal(parseFloat(redeemCodeInfo?.AmountToRedeem + parseInt(redeemCodeInfo?.AddedToDonationAmount)))
      }
    }
    fetchData()
    if (redemptionData?.Charities) setEditMode(true)
  }, [])

  useEffect(() => {
    const doAutomaticRedeem = async () => {
      const { CharityId } = redeemCodeInfo
      const { CHARITYID, PRESELCHARID, CID } = parseQuerystring(window.location.search.toUpperCase())
      const charityToRedeemTo = fullCharityList?.find(
        c => [CharityId, parseInt(CHARITYID), parseInt(PRESELCHARID), parseInt(CID)].includes(c.CharityId)
      )

      if (charityToRedeemTo) {
        setRedemptionData({
          ...redemptionData,
          Charities: [charityToRedeemTo],
        })

        if (CharityId || CHARITYID) {
          await handleContinue(charityToRedeemTo)
        }
      }
    }
    doAutomaticRedeem()
  }, [search, loadingAppData])

  const handlePreselectedCharity = charity => {
    if (!Charities?.find(item => item === charity) && (Charities?.length ?? 0) < 3) {
      setRedemptionData({
        ...redemptionData, Charities: [...Charities, charity],
      })
    }
  }
  const getCodesSum = async () => {
    let sum = 0
    for (const code of codes) {
      try {
        const result = await getRedeemCodeInfo(code, loggedInUser.UserId)
        if (result && result?.AmountToRedeem) {
          sum += result.AmountToRedeem
        }
      } catch (error) {
        console.error("Error fetching redeem code info:", error)
      }
    }
    return sum
  }

  const handleRemoveCharity = charityId => () => {
    setRedemptionData({
      ...redemptionData, Charities: Charities.filter(({ CharityId }) => CharityId !== charityId),
    })
  }
  const handleSetShareInfo = () => {
    setShareInfo(!shareInfo)
    if (!shareInfo) setRedemptionData(data => ({
      ...data,
      ShareInfo: {
        fName: "",
        lName: "",
        email: "",
      },
    }))
  }
  const afterAddCharity = () => {
    scrollToElement("#divCharityChosen", true, 0)
  }

  const handleChangeField = e => {
    const { id, value } = e.target
    setRedemptionData(data => ({
      ...data,
      ShareInfo: {
        ...data.ShareInfo,
        [id]: value,
      },
    }))
  }

  const handleContinue = async charity => {
    const payload = {
      RedeemCode: redemptionData.code,
      Charities: (charity && charity.CharityId
        ? [{ CharityId: charity.CharityId }]
        : Charities.map(c => ({ CharityId: c.CharityId }))),
      ShareRedeemerInfo: redemptionData.shareInfo,
    }
    setLoading(true)
    const { Succeeded, ErrorMessage } = await RedeemCodes(payload)
    setLoading(false)

    Succeeded ? setSuccess(true) : setError(ErrorMessage)
  }
  return (<div className={styles.container}>
    <div className={styles.charityChosen} id="divCharityChosen">
      <Alert color="danger" isOpen={!!error} toggle={() => setError()}>
        <div className={styles.errorWrapper}>{error}</div>
      </Alert>
      <div
        className={styles.lblCharity}>{Charities && Charities.length ? "Your selected " + (Charities.length > 1 ? "Charities" : "Charity") : "Please choose your charity"}</div>
      {Charities && Charities.length ? Charities.map(({ CharityId, LogoUrl, CharityName, CategoryName }, index) => (
        <div key={index} className={styles.preselectedCharity}>
          <div className={styles.itemImage}>
            {LogoUrl && <img src={LogoUrl} alt={CharityName} />}
            <div
              className={styles.btnRemove}
              onClick={handleRemoveCharity(CharityId)}>
              <i className="fa-solid fa-xmark" />
            </div>
          </div>
          <div className={styles.itemDetail}>
            <div className={styles.itemName}>{CharityName}</div>
            <div className={styles.itemDesc}>{CategoryName}</div>
          </div>
        </div>)) : null}
      {!HideAmountAllOrders && (<div className={styles.priceTotal}>
        {redeemCodeInfo?.HideAmount && addToDonationAmount > 0 ? (<>
          Total: Original donation + ${addToDonationAmount}
        </>) : (<>
          Total : ${total}
        </>)}
        <p>* Amount will be split between the charities evenly.</p>
      </div>)}
      <Label check className={styles.shareContact}>
        <Input
          type="radio"
          checked={shareInfo == true}
          onChange={() => handleSetShareInfo()}
        />
        <span className={styles.lblTerms}>
          Please share my info with the charities I’ve selected!
        </span>
        {shareInfo &&
          <div className={styles.shareInfoForm}>
            <input id={"fName"} className={styles.shareInfoInput} type={"text"} placeholder={"First Name"}
              value={redemptionData.ShareInfo.fName} onChange={(e) => handleChangeField(e)} />
            <input id={"lName"} className={styles.shareInfoInput} type={"text"} placeholder={"Last Name"}
              value={redemptionData.ShareInfo.lName} onChange={(e) => handleChangeField(e)} />
            <br />
            <input id={"email"} className={styles.shareInfoInput} type={"email"} placeholder={"Email"}
              value={redemptionData.ShareInfo.Email} onChange={(e) => handleChangeField(e)} />
          </div>
        }
      </Label>
      <Button
        className={styles.btnContinue}
        disabled={!Charities.length}
        loading={loading}
        onClick={handleContinue}>
        {addToDonationAmount > 0 ? "Confirm and Check Out" : "Confirm Selections"}
      </Button>
      <button className={styles.btnStepBack} onClick={onBack}>
        Go Back
        <i className="fa fa-undo" />
      </button>
    </div>
    <div className={styles.selectCharity}>
      <div>
        <CharitySelector
          setSelCharityList={setCharityList}
          fromRedemption={true}
          charities={Charities || []}
          onUpdate={handlePreselectedCharity}
          afterAddCharity={afterAddCharity} />
      </div>
    </div>
  </div>)
}

export default Step2
