import classnames from "classnames"
import React, { useState, useEffect } from "react"
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { GetHiddenCharities, StatesList } from "../../../Code/Data"
import { filterCharities, parseQuerystring } from "../../../Code/Utilities"
import { useAppData } from "../../../Context/appData"
import CharityDetailModal from "../CharityDetailModal"
import LoadingSpinner from "../LoadingSpinner"
import styles from "./styles.module.scss"
import { useLocation } from "react-router"
import { useRedemptionData } from "../../../Context/redemption"

const CHARITY_LOCALES = [
  {
    value: "INTERNATIONAL",
    label: "International",
  },
  {
    value: "NATIONAL",
    label: "National",
  },
  ...StatesList,
]

export default function CharitySelector({
                                          color,
                                          query,
                                          charities,
                                          onUpdate,
                                          fromRedemption,
                                          afterAddCharity,
                                          setSelCharityList,
                                        }) {
  const [searchQuery, setSearchQuery] = useState(query)
  const [categoryId, setCategoryId] = useState()
  const [viewCharity, setViewCharity] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { redeemCodeInfo } = useRedemptionData()
  const [location, setLocation] = useState()
  const [filterByMainList] = useState()
  const [filterByTop50List, setFilterByTop50List] = useState()
  const [filterByTop10, setFilterByTop10] = useState()
  const [filterByMajorList, setFilterByMajorList] = useState()
  const { fullCharityList, charityCategories } = useAppData()
  const [listToDisplay, setListToDisplay] = useState(fullCharityList)
  const { search } = useLocation()
  const { PreselCatID } = parseQuerystring(search)

  const handleUpdateSearchQuery = e => {
    setSearchQuery(e.target.value)
  }

  const handleUpdate = charity => {
    setViewCharity(false)
    onUpdate(charity)
    if (afterAddCharity) {
      afterAddCharity()
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (fullCharityList && charities && !fromRedemption) {
      const chars = fullCharityList.filter(charity =>
        charities.includes(charity.CharityId),
      )
      setSelCharityList(chars)
    }
    setIsLoading(!(!!fullCharityList && !!listToDisplay && !!charityCategories))
  }, [])

  useEffect(() => {
    if (fullCharityList && !listToDisplay) {
      setListToDisplay(fullCharityList)
    }   
    setIsLoading(!(!!fullCharityList && !!listToDisplay && !!charityCategories))
  }, [fullCharityList, listToDisplay, charityCategories])

  useEffect(() => {
    if(!isLoading){
    getFilteredCharityList()}
  }, [filterByMajorList, filterByMainList, filterByTop10, filterByTop50List, searchQuery, search, isLoading])
  
  if (isLoading) {
    return <LoadingSpinner />
  }

  const getFilteredCharityList = async () => {
    let sortedList = [...fullCharityList]
    let filteredList = []

    if (!!redeemCodeInfo?.AddedCategoryIds && redeemCodeInfo?.AddedCategoryIds.length > 0) {
      let addedCatIdCharities = []
      for (let i = 0; i < redeemCodeInfo?.AddedCategoryIds?.length; i++) {
        let result = await GetHiddenCharities(redeemCodeInfo.AddedCategoryIds[i])
        addedCatIdCharities = addedCatIdCharities.concat(result)
      }
      let list = redeemCodeInfo.OnlyShowAddedCategoryIds ? addedCatIdCharities : [...fullCharityList, ...addedCatIdCharities]
      let uniqueArr = [...new Set(list)]
      filteredList = uniqueArr
    }
    else {
      filteredList = [...fullCharityList]
    }
    const listname = filterByMainList ? "main" : "all"

    if (filterByMajorList) {
      filteredList = sortedList.filter(c => !c.IsLocal)
    }
    if (filterByTop50List) {
      filteredList = sortedList
        .filter(c => c.Rank === 1 || c.Rank === 2)
        .slice(0, 50)
    }
    if (filterByTop10) {
      filteredList = sortedList.filter(c => c.Rank === 2)
    }
    if (searchQuery) {
      filteredList = filteredList.filter(charity =>
        charity.CharityName.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    }
    if (PreselCatID || categoryId || filterByMainList) {
      filteredList = filterCharities(
        filteredList,
        searchQuery,
        PreselCatID || categoryId,
        listname,
      )
    }
    if (location && !filterByMainList) {
      filteredList = filteredList.filter(
        charity =>
          charity.IsLocal &&
          charity.Locations &&
          charity.Locations.includes(location),
      )
    }

    const uniqueFilteredList = [
      ...new Map(filteredList.map(c => [c.CharityName, c])).values(),
    ]
    setListToDisplay(uniqueFilteredList)
  }

  const categoryFilterName =
    PreselCatID || categoryId
      ? charityCategories.find(
        ({ CategoryId }) => CategoryId === (PreselCatID || categoryId),
      )?.CategoryName
      : "Filter By Category"

  const locationFilterName = location
    ? CHARITY_LOCALES.find(({ value }) => value === location)?.label
    : "Filter By Location"

  return (
    <>
      <div className="container-fluid">
        <div className={styles.header}>
          <div className={styles.headerRow}>
            <div className={styles.inputSearch} style={{ borderColor: color }}>
              <input
                className={styles.input}
                placeholder="Search"
                value={searchQuery}
                onChange={handleUpdateSearchQuery}
              />
              <i className="fa-solid fa-magnifying-glass" style={{ color }} />
            </div>
            <div className={styles.wrapper}>
              <UncontrolledDropdown
                className={styles.filterWrapper}
                disabled={!!PreselCatID}>
                <DropdownToggle
                  className={styles.filterTrigger}
                  nav
                  caret
                  style={{ color }}>
                  {categoryFilterName}
                </DropdownToggle>
                <DropdownMenu className={styles.filterList}>
                  <DropdownItem
                    className={classnames(styles.filterItem, styles.clear)}
                    onClick={() => setCategoryId()}>
                    Clear
                  </DropdownItem>
                  {charityCategories.map(({ CategoryId, CategoryName }) => (
                    <DropdownItem
                      key={CategoryId}
                      className={styles.filterItem}
                      onClick={() => setCategoryId(CategoryId)}>
                      {CategoryName}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            {!filterByMainList && (
              <UncontrolledDropdown className={styles.filterWrapper}>
                <DropdownToggle
                  className={styles.filterTrigger}
                  nav
                  caret
                  style={{ color }}>
                  {locationFilterName}
                </DropdownToggle>
                <DropdownMenu className={styles.filterList}>
                  <DropdownItem
                    className={classnames(styles.filterItem, styles.clear)}
                    onClick={() => setLocation()}>
                    Clear
                  </DropdownItem>
                  {CHARITY_LOCALES.map(({ value, label }) => (
                    <DropdownItem
                      key={value}
                      className={styles.filterItem}
                      onClick={() => setLocation(value)}>
                      {label}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </div>

          <div className={styles.content}>
            <div className={styles.divFilters}>
              <div>
                <input
                  type="checkbox"
                  checked={filterByTop50List}
                  onChange={e => setFilterByTop50List(e.target.checked)}
                  name="filterByTop50List"
                />
                <label target="filterByTop50List">
                  {" "}
                  Only display top causes
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={filterByTop10}
                  onChange={e => setFilterByTop10(e.target.checked)}
                  name="filterByTop10"
                />
                <label target="filterByTop10">
                  Only display favorite popular causes
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={filterByMajorList}
                  onChange={e => setFilterByMajorList(e.target.checked)}
                  name="filterByMajorList"
                />
                <label target="filterByMajorList">
                  Only display major charities
                </label>
              </div>
            </div>
            <div className={styles.tip}>Choose up to 3 charities to redeem below.</div>

            <div className={styles.charityList}>
              {listToDisplay.map(charity => {
                const selected = charities.includes(charity.CharityId) || charities.includes(charity)
                return (
                  <div
                    key={charity.CharityId}
                    className={styles.charityItem}
                    onClick={() => !selected && !viewCharity && handleUpdate(charity)}
                    value={charity.CharityId}>
                    <div
                      className={styles.btnView}
                      onClick={(event) => {
                        event.stopPropagation()
                        setViewCharity(charity)
                      }}>
                      <i className="fa-solid fa-eye" />
                      View
                    </div>
                    <div
                      className={classnames(styles.charityName, {
                        [styles.selected]: selected,
                      })}
                      style={{ color: selected && color }}>
                      {charity.CharityName}
                    </div>
                    {selected && (
                      <div className={styles.iconSelected} style={{ color }}>
                        <i className="fa-solid fa-circle-check" />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <CharityDetailModal
          charity={viewCharity}
          onClose={() => setViewCharity()}
          onSelect={handleUpdate}
        />
      </div>
    </>
  )
}
