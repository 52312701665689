import React, { createContext, useContext, useState } from "react"
import {
  DeleteRedemptionPage,
  GetRedeemCodeInfo,
  GetRedeemInfoFromServerSession,
  GetRedemptionPage,
  ScrambledRedeemCode,
} from "../Code/Data"

const RedemptionDataContext = createContext()

export const RedemptionDataProvider = ({ children }) => {
  const [redeemCodeInfo, setRedeemCodeInfo] = useState()
  const [redemptionPageInfo, setRedemptionPageInfo] = useState()
  const [redemptionData, setRedemptionData] = useState({
    Charities: [],
    ShareInfo: {
      fName: "",
      lName: "",
      email: ""
    },
  })
  const [redemptionPageData, setRedemptionPageData] = useState({
    hasLogo: true,
    MainColor: "#aaa",
    AccentColor: "#555",
    Charities: [],
  })

  async function getRedeemCodeInfo(redeemCode, userId) {
    if (!redeemCode) return { Succeeded: false, ErrorMessage: "Code is missing" };
    const redeemCodeInfo = await GetRedeemCodeInfo(redeemCode)
    const { Succeeded, RedeemInfo, ErrorMessage } = redeemCodeInfo || {}
    const rci = { ...RedeemInfo, Succeeded, ErrorMessage }
    setRedeemCodeInfo(rci)
    if (Succeeded) {
      if (!userId) {
        setRedemptionPageInfo(RedeemInfo?.UserRedemptionPageInfo)
      }
      else {
        getRedemptionPageInfo(userId)
      }
    }
    else {
      setRedemptionPageInfo()
    }
    return rci
  }

  async function getScrambledRedeemCode(scrambledCode, userId) {
    const scrambledRedeemCodeInfo = await ScrambledRedeemCode(scrambledCode)
    const { Succeeded, RedeemInfo, ErrorMessage } = scrambledRedeemCodeInfo || {}
    const rci = { ...RedeemInfo, Succeeded, ErrorMessage }
    debugger
    setRedeemCodeInfo(rci)
    if (Succeeded) {
      if (!userId) {
        setRedemptionPageInfo(RedeemInfo?.UserRedemptionPageInfo)
      }
      else {
        getRedemptionPageInfo(userId)
      }
    }
    else {
      setRedemptionPageInfo()
    }
    return rci
  }

  async function getRedeemInfoFromServerSession() {
    const redeemCodeInfo = await GetRedeemInfoFromServerSession()
    const { Succeeded, RedeemInfo, ErrorMessage } = redeemCodeInfo || {}
    const rci = { ...RedeemInfo, Succeeded, ErrorMessage }
    if (RedeemInfo && !RedeemInfo.Charities) RedeemInfo.Charities = []
    setRedeemCodeInfo(rci)
    if (Succeeded) {
      setRedemptionPageInfo(RedeemInfo?.UserRedemptionPageInfo)
    }
    else {
      setRedemptionPageInfo()
    }
    return rci
  }

  async function deleteRedemptionPage(userId) {
    const { Succeeded } = await DeleteRedemptionPage(userId)
    return Succeeded
  }


  async function getRedemptionPageInfo(userId) {
    if (!redemptionPageInfo || redemptionPageInfo.UserId !== userId) {
      const { UserRedemptionPageInfo } = await GetRedemptionPage(userId)
      setRedemptionPageInfo(UserRedemptionPageInfo || undefined)
    }
    return redemptionPageInfo
  }

  return (
    <RedemptionDataContext.Provider
      value={{
        getRedeemCodeInfo,
        getScrambledRedeemCode,
        getRedeemInfoFromServerSession,
        getRedemptionPageInfo,
        redemptionData,
        setRedemptionData,
        redemptionPageData,
        setRedemptionPageData,
        setRedeemCodeInfo,
        redeemCodeInfo,
        redemptionPageInfo,
        deleteRedemptionPage,
      }}>
      {children}
    </RedemptionDataContext.Provider>
  )
}

export const useRedemptionData = () => useContext(RedemptionDataContext)
